import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';

export default function Sidebar(){
    return (<>
        <div className={styles['sidebar']}>
            <img src="../assets/images/logo-light.png" alt="logo" />
            <div className={styles['sidemenu']}>
                <ul>
                    <li><NavLink to="/admin/users">Users</NavLink></li>
                    <li><NavLink to="/admin/qr-category">QR Category</NavLink></li>
                    {/* <li>Active QR Code</li>
                    <li>Inactive QR Code</li>
                    <li>My Account</li>
                    <li>Settings</li> */}
                </ul>
                <ul>
                    <li><NavLink to="/admin/logout">Logout</NavLink></li>
                </ul>
            </div>
        </div>
    </>)
}
