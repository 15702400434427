import React, { useState } from 'react';
import styles from './pricing.module.css';

export default function Pricing(){
    const [ billingType, setBillingType ] = useState('monthly');

    return (<>
        <div className='container'>
            <div className={styles['qr-code-generation']}>
                <h2>We've got a plan that's perfect for you</h2>
                <ul className={styles['pricing-tabs']}>
                    <li className={styles[billingType === 'monthly' && 'active']} onClick={() => setBillingType('monthly')}>Monthly Billing</li>
                    <li className={styles[billingType === 'annually' && 'active']} onClick={() => setBillingType('annually')}>Annually Billing</li>
                </ul>
                <div className={styles['pricing-container']}>
                    {/* <div className={styles['pricing-box']}>
                        <div className={styles['plan-price']}>
                            <p>Basic plan</p>
                            <h3>₹99<span>per month</span></h3>
                            <span>Our most popular plan</span>
                            <div className={styles['get-started']}>Get started</div>
                        </div>
                        <p className={styles['features']}>Features</p>
                        <ul className={styles['features-list']}>
                            <li>10 dynamic QR codes</li>
                            <li>10,000 scans</li>
                            <li>1 user</li>
                            <li><strike>Bulk creation</strike></li>
                            <li><strike>API requests</strike></li>
                        </ul>
                    </div> */}
                    <div className={styles['pricing-box']}>
                        <div className={styles['plan-price']}>
                            <p>Basic plan</p>
                            <h3>Free</h3>
                            <span>Lifetime</span>
                            <div className={styles['get-started']}>Get started</div>
                        </div>
                        <p className={styles['features']}>Features</p>
                        <ul className={styles['features-list']}>
                            <li>Create unlimited static and dynamic QR codes.</li>
                            <li>Access to Generative AI Content Optimization (Limited)</li>
                            <li>Choose from various QR types: URL, PDF, Menu, and more.</li>
                            <li>Download QR codes in multiple formats: PNG, SVG, PDF, and others.</li>
                            <li>Unlimited scans: QR codes can be scanned an unlimited number of times.</li>
                            <li>Real-time QR code updates.</li>
                            <li>Flexible cancellation policy without restrictions.</li>
                        </ul>
                    </div>
                    <div className={styles['pricing-box']}>
                        <div className={styles['plan-price']}>
                            <p>Standard plan</p>
                            <h3>₹2500<span>per month</span></h3>
                            <span>6 Months</span>
                            <div className={styles['get-started']}>Get started</div>
                        </div>
                        <p className={styles['features']}>Features</p>
                        <ul className={styles['features-list']}>
                            <li>Includes all Basic Plan features.</li>
                            <li>Enhanced Generative AI Content Optimization</li>
                            <li>Limited Generative AI Image Optimization</li>
                            <li>Invite unlimited collaborators to manage QR codes.</li>
                            <li>Comprehensive QR analytics: Track scans, locations, and user devices.</li>
                            <li>Customize QR codes with colors, fonts, and logos.</li>
                            <li>Semi-Whitelabel Domain: Utilize abc.scanon.com for your QR codes.</li>
                            <li>Access to business growth and marketing templates.</li>
                            <li>Unlimited scans.</li>
                        </ul>
                    </div>
                    <div className={styles['pricing-box']}>
                        <div className={styles['plan-price']}>
                            <p>Pro and enterprise plan</p>
                            <h3>₹3500<span>per month</span></h3>
                            <span>3 Months</span>
                            <div className={styles['get-started']}>Get started</div>
                        </div>
                        <p className={styles['features']}>Features</p>
                        <ul className={styles['features-list']}>
                            <li>Includes all Basic and Standard Plan features.</li>
                            <li>Advanced Generative AI E-commerce and Product Listing Solution</li>
                            <li>Enhanced Generative AI Image and Video Optimization</li>
                            <li>Generative AI Content Selection for SEO and Keyword Ranking</li>
                            <li>Integrate Google and Facebook pixels to track QR code interactions.</li>
                            <li>Developer APIs for REST API access for QR code management.</li>
                            <li>Premium Support for priority and personalized technical assistance.</li>
                            <li>Custom domain and Whitelabel options: Use your domain (e.g., abc.com) for QR codes.</li>
                            <li>Templates available for E-commerce, Real Estate, Restaurant, and Hotel Management categories.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
