import React from 'react';
import styles from './navbar.module.css';

export default function Navbar(props){
    return (<>
        <div className={styles['navbar']}>
            <h1 className={styles['main-heading']}>{props.heading}</h1>
            <h2 className={styles['subheading']}>{props.subheading}</h2>
        </div>
    </>)
}
