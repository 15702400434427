import React, { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import Message from '../../../Layouts/Message/Message';
import api, { userRegistration, registrationGoogle } from '../../../Base/Config';
import styles from '../login.module.css';

export default function Signup(props){
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageType, setMessageType ] = useState('');
    const [ googleLoader, setGoogleLoader ] = useState(false);

    const submitFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(userRegistration, {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            password: password
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const login = useGoogleLogin({
        onSuccess: async response => {
            try {
                googleAuth(response.access_token);
            } catch (error) {
                console.error(error);
            }
        }
    });

    const googleAuth = (token) => {
        setGoogleLoader(true);
        api.post(registrationGoogle, {
            token: token
        })
        .then(res => {
            setGoogleLoader(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                setMessageType('success');
                setTimeout(() => {
                    props.changeFunction(false);
                }, 2000);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setGoogleLoader(false);
            console.error(err);
        })
    }

    return (<>
        {googleLoader && <div className={styles['google-loader']}>
            <div>Signing up. Please wait.</div>
        </div>}
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <form onSubmit={submitFunction}>
            <h1>Welcome</h1>
            <label>FIRST NAME*</label>
            <input type="text" placeholder="Enter first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required/>
            <label>LAST NAME*</label>
            <input type="text" placeholder="Enter last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required/>
            <label>PHONE</label>
            <input type="text" placeholder="Enter last name" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
            <label>EMAIL ADDRESS*</label>
            <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
            <label>PASSWORD*</label>
            <div className={styles['hide-show']}>
                <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <div onClick={() => setShowPassword(!showPassword)}><span className={`fa fa-${showPassword ? 'eye' : 'eye-slash'}`}></span></div>
            </div>
            <button className="button black-button" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
        </form>
        <p className={styles['signin']}>Already a user? <span onClick={() => props.changeFunction(false)}>Sign in</span> now</p>
        <div className={styles['or']}>
            <p className={styles['or-line']}></p>
            <p className={styles['or-text']}><span>OR</span></p>
        </div>
        <div className={styles['google-auth']} onClick={() => login()}>
            <img src="./assets/images/google-icon.svg" alt="google-icon" />
            <span>Sign up with Google</span>
        </div>
    </>)
}
