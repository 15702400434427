import React, { useState } from 'react';
import Message from '../../../Layouts/Message/Message';
import tokenapi, { resetPassword, setPassWord } from '../../../Base/TokenConfig';
import styles from '../profile.module.css';

export default function ResetPassword(props) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const changePasswordFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(resetPassword, {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmPassword
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                emptyValues();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const addPasswordFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        tokenapi.post(setPassWord, {
            newPassword: newPassword,
            confirmNewPassword: confirmPassword
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                emptyValues();
                props.fetchFunction();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const emptyValues = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <div className={styles['profile-card']}>
            <h2>{props.password ? 'Change' : 'Add'} password</h2>
            <form onSubmit={props.password ? changePasswordFunction : addPasswordFunction}>
                <div className={styles['profile-card-details']}>
                    {props.password && <div>
                        <label>Current password</label>
                        <input type="password" placeholder="Enter new password here" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required/>
                    </div>}
                    <div>
                        <label>New password</label>
                        <input type="password" placeholder="Enter new password here" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                    </div>
                    <div>
                        <label>Confirm password</label>
                        <input type="text" placeholder="Enter confirm password here" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                    </div>
                    {props.password && <div></div>}
                    <div>
                        <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Wait...' : <>{props.password ? 'Change password' : 'Set password'}</>}</button>
                    </div>
                </div>
            </form>
        </div>
    </>)
}