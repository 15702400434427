import React, { useState } from 'react';
import ChooseCategory from './Components/ChooseCategory';
import Content from './Components/Content';
import QRDesign from './Components/QRDesign';
import styles from './create-qr-code.module.css';
import Navbar from '../Components/Navbar/Navbar';

export default function CreateQRCode(){
    const [ step, setStep ] = useState(1);

    const stepChangeFunction = (x) => setStep(x);

    const Steps = ({number}) => {
        return (
            <div className={styles['create-qr-steps']}>
                <p className={styles[number === 1 ? 'current' : (number > 1 && 'completed')]}>
                    <span className={styles['step-number']}>1</span>
                    <span className={styles['step-name']}>Choose category</span>
                    <span className={styles['step-angle']}></span>
                </p>
                <p className={styles[number === 2 ? 'current' : (number > 2 ? 'completed' : 'next')]}>
                    <span className={styles['step-number']}>2</span>
                    <span className={styles['step-name']}>Content</span>
                    <span className={styles['step-angle']}></span>
                </p>
                <p className={styles[number === 3 ? 'current' : (number > 3 ? 'completed' : 'next')]}>
                    <span className={styles['step-number']}>3</span>
                    <span className={styles['step-name']}>QR design</span>
                    <span className={styles['step-angle']}></span>
                </p>
            </div>
        )
    }

    return (<>
        <Navbar heading='Create QR Code' subheading='Generate Customized QR Codes' />
        <div className="dashboard-content">
            <div className={styles['create-qr-code-steps']}>
                <Steps number={step} />
                {step > 1 && <div className={styles['back-to-category']} onClick={() => stepChangeFunction(step - 1)}><span className="fa fa-angle-double-left"></span> Back</div>}
            </div>
            {step === 1 && <ChooseCategory stepChangeFunction={stepChangeFunction} />}
            {step === 2 && <Content stepChangeFunction={stepChangeFunction} />}
            {step === 3 && <QRDesign stepChangeFunction={stepChangeFunction} />}
        </div>
    </>)
}
