import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from "./login.module.css";
import Signup from './Components/Signup';
import Signin from './Components/Signin';

export default function Login(){
    const [ registration, setRegistration ] = useState(false);

    const changeFunction = (x) => setRegistration(x);

    return (<>
        <div className={styles['content']}>
            <div>
                <Link to="/"><img src="../assets/images/logo.png" alt="logo" /></Link>
                {registration ? <Signup changeFunction={changeFunction} /> : <Signin changeFunction={changeFunction} />}
            </div>
        </div>
        <p className={styles['copyright']}>© {new Date().getFullYear()} ScanOnn | All rights reserved</p>
    </>)
}
