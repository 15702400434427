import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenapi, { getUserById } from '../../Base/TokenConfig';
import ResetPassword from './Components/ResetPassword';
import styles from './profile.module.css';
import Navbar from '../Components/Navbar/Navbar';

export default function Profile(){
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState('');
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        tokenapi.get(getUserById)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setProfileDetails(res.data.result);
            } else if(res.data.code === 401) {
                navigate('/logout');
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    return (<>
        <Navbar heading='Profile' subheading='Account Info & Password Management' />
        <div className="dashboard-content">
            {
                loader
                ?<>
                    <div className={styles['loader-box']} style={{height:'194px'}}></div>
                    <div className={styles['loader-box']} style={{height:'296px'}}></div>
                </>
                :<>
                    <div className={styles['profile-card']}>
                        <h2>Personal information</h2>
                        <div className={styles['profile-card-details']}>
                            <p>
                                <span>First name</span>
                                {profileDetails.firstName}
                            </p>
                            <p>
                                <span>Last name</span>
                                {profileDetails.lastName}
                            </p>
                            <p>
                                <span>Email</span>
                                {profileDetails.email}
                            </p>
                            {profileDetails.phone && <p>
                                <span>Phone</span>
                                {profileDetails.phone}
                            </p>}
                            <p>
                                <span>Account created on</span>
                                {`${new Date(profileDetails.createdAt)}`.substr(0, 24)}
                            </p>
                        </div>
                    </div>
                    <ResetPassword password={profileDetails.password} fetchFunction={fetchFunction} />
                </>
            }
        </div>
    </>)
}
