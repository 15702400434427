import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenapi, { getQrList, deleteQrById } from '../../Base/TokenConfig';
import { setQrDetail, removeQrDetail } from '../../Utils/Common';
import Message from '../../Layouts/Message/Message';
import Modal from '../../Layouts/Modal/Modal';
import WebsiteDetails from './Components/WebsiteDetails';
import VcardDetails from './Components/VcardDetails';
import UrlDetails from './Components/UrlDetails';
import styles from './my-qr-codes.module.css';
import { downloadQr } from '../../Utils/downloadQr';
import Navbar from '../Components/Navbar/Navbar';

export default function MyQRCodes(){
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [qrArray, setQrArray] = useState([]);
    const [singleDetail, setSingleDetail] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [singleQr, setSingleQr] = useState(false);
    const [qrModal, setQrModal] = useState(false);
    const [loader, setLoader] = useState(true);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        removeQrDetail();
        fetchFunction(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = (number) => {
        tokenapi.get(getQrList + number)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setQrArray(res.data.records);
                setPageNumber(parseInt(res.data.pageNumber));
                setTotalPages(Math.ceil(res.data.totalRecords/res.data.pageSize));
            } else if(res.data.code === 401) {
                navigate('/logout');
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const openEditModal = (object) => {
        setSingleDetail(object);
        setEditModal(true);
    }

    const deleteFunction = (id) => {
        tokenapi.delete(deleteQrById + id)
        .then(res => {
            if(res.data.code === 200){
                setMessageType('success');
                fetchFunction();
                setEditModal(false);
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            console.error(err);
        })
    }

    const openDetail = (detail) => {
        setQrDetail(detail);
        navigate('/qr-code-detail');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {editModal && <Modal closeModal={() => setEditModal(false)}>
            <div className={styles["modal-box"]}>
                <div className={styles['modal-heading']}>Details <img src="./assets/images/times.png" onClick={() => setEditModal(false)} alt="" /></div>
                {singleDetail.categoryName === 'Website' && <WebsiteDetails singleDetail={singleDetail} fetchFunction={fetchFunction} deleteFunction={deleteFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'Visiting Card' && <VcardDetails singleDetail={singleDetail} fetchFunction={fetchFunction} deleteFunction={deleteFunction} closeFunction={() => setEditModal(false)} />}
                {singleDetail.categoryName === 'URL' && <UrlDetails singleDetail={singleDetail} fetchFunction={fetchFunction} deleteFunction={deleteFunction} closeFunction={() => setEditModal(false)} />}
            </div>
        </Modal>}
        {qrModal && <Modal closeModal={() => setQrModal(false)}>
            <div className={styles["qr-modal-box"]}>
                <img src={singleQr} alt="single-qr" />
            </div>
        </Modal>}
        <Navbar heading='My QR Codes' subheading='View and Manage Your QR Codes' />
        <div className="dashboard-content">
            {
                loader
                ?<>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                </>
                :<>
                    {
                        qrArray.length > 0
                        ?<>
                            {qrArray.map((item, i) => (
                                <div className={styles['list-card']} key={i}>
                                    <div className={styles['list-card-content']}>
                                        <div className={styles['basic-info']}>
                                            <div>
                                                <p className={styles['qr-title']}>{item.title}</p>
                                                <p className={styles['qr-category']}>{item.categoryName}</p>
                                            </div>
                                            <div className={styles['qr-image']}>
                                                <img src={item.s3Url} alt="" onClick={() => {setSingleQr(item.s3Url); setQrModal(true);}} />
                                            </div>
                                        </div>
                                        <div className={styles['other-info']}>
                                            <div>
                                                {item.encWebUrl && <p className={styles['qr-info']}><i className="fa fa-globe"></i> {item.encWebUrl}</p>}
                                                <p className={styles['qr-info']}><i className="fa fa-calendar"></i> <span>Created:</span> {`${new Date(item.createdAt)}`.substr(0,15)}</p>
                                                {item.updatedAt && <p className={styles['qr-info']}><i className="fa fa-pencil"></i> <span>Modified:</span> {`${new Date(item.updatedAt)}`.substr(0,15)}</p>}
                                            </div>
                                            <p className={styles['qr-scans']}>{item.totalScans ? item.totalScans : '0'}<span>{item.totalScans === 1 ? 'Scan' : 'Scans'}</span></p>
                                            <div className={styles['list-action']}>
                                                <p className={styles['detail-button']} onClick={() => openDetail(item)}>Details</p>
                                                <p className={styles['download-button']} onClick={() => downloadQr(item.s3Url)}><span className="fa fa-download"></span></p>
                                                <p className={styles['download-button']} onClick={() => openEditModal(item)}><span className="fa fa-edit"></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {totalPages !== 1 && <div className={styles['pagination']}>
                                {
                                    pageNumber === 1
                                    ?<span className={styles['disabled']}><i className="fa fa-angle-left"></i></span>
                                    :<span onClick={() => fetchFunction(pageNumber - 1)}><i className="fa fa-angle-left"></i></span>
                                }
                                {Array(totalPages).fill(null).map((_, i) => (
                                    <span className={styles[(pageNumber === i + 1) ? 'active' : '']} onClick={() => fetchFunction(i + 1)} key={i}>{i + 1}</span>
                                ))}
                                {
                                    pageNumber === totalPages
                                    ?<span className={styles['disabled']}><i className="fa fa-angle-right"></i></span>
                                    :<span onClick={() => fetchFunction(pageNumber + 1)}><i className="fa fa-angle-right"></i></span>
                                }
                            </div>}
                        </>
                        :<p className={styles['no-qr-created']}> No QR created </p>
                    }
                </>
            }
        </div>
    </>)
}
