import React, { useState } from 'react';
import { faqs } from '../Components/FAQs';
import styles from './faqs.module.css';

export default function FAQs(){
    const [ activeFaq, setActiveFaq ] = useState(1);

    return (<>
        <section>
            <div className='container'>
                <div className={styles['qr-code-generation']}>
                    <h2>Frequently Asked Questions</h2>
                        {faqs.map((item, i) => (
                            <div className={styles['faq-blocks']} key={i}>
                                <div onClick={() => setActiveFaq(item.id)}>
                                    <i className={`fa fa-${activeFaq === item.id?"minus":"plus"}-circle float-right`}></i>
                                    <p className={styles['faq-question']}>0{item.id} <span> {item.question}</span></p>
                                </div>
                                <div className={`collapse ${activeFaq === item.id?"show":"hide"}`}>
                                    <div className={styles['faq-answers']}>
                                        {item.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    </>)
}
