import React from 'react';
import styles from './modal.module.css';

export default function Modal(props){
    return (<>
        <div className={styles["modal-container"]}>
            <div className={styles["modal-background"]} onClick={() => props.closeModal()}></div>
            <div className={styles["modal-box"]}>
                {props.children}
            </div>
        </div>
    </>)
}
