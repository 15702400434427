import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Message from '../../../Layouts/Message/Message';
import styles from '../my-qr-code-detail.module.css';

export default function QrDetails(props) {
    const data = props.data;
    const empty = 'Incomplete';
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const copytext = () => {
        setMessageType('success');
        setMessage('Link copied clipboard.');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {
            data.url
            ?<div className={styles['qr-detail']}>
                <div className={styles['qr-basic-detail']}>
                    <img src={data.s3Url} alt="QR" />
                </div>
                <p className={`${styles['qr-detail-item']} my-3`}>
                    <span>Link: </span>
                    {data.encWebUrl}
                    <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                        <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                    </CopyToClipboard>
                </p>
                <div className={styles['qr-detail-section']}>
                    <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                    <p className={styles['qr-detail-item']}><span>URL:</span> {data.url ? data.url : <i>{empty}</i>}</p>
                </div>
                <div className={styles['qr-detail-section-heading']}>QR detail</div>
                <div className={styles['qr-detail-section']}>
                    {data.totalScans && <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans}</p>}
                    {data.uniqueViews && <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews}</p>}
                    {data.createdAt && <p className={styles['qr-detail-item']}><span>Created at:</span> {`${new Date(data.createdAt)}`.substr(0,24)}</p>}
                    {data.updatedAt && <p className={styles['qr-detail-item']}><span>Updated at:</span> {`${new Date(data.updatedAt)}`.substr(0,24)}</p>}
                </div>
            </div>
            :<div className={styles['qr-detail']}>
                <div className={styles['qr-basic-detail']}>
                    <img src={data.s3Url} alt="QR" />
                    {/* <div>
                        <p className={styles['vcard-name']}>{data.name}</p>
                        <p className={styles['vcard-designation']}>{data.designation}</p>
                    </div> */}
                    {
                        data.photo
                        ?<img src={data.photo} alt={data.name} />
                        :<img src={`https://robohash.org/${data.requestId}`} alt={data.name} />
                    }
                </div>
                <p className={`${styles['qr-detail-item']} my-3`}>
                    <span>Link: </span>
                    {data.encWebUrl}
                    <CopyToClipboard text={data.encWebUrl} onCopy={() => copytext()}>
                        <i className={`fa fa-copy ${styles['copy-icon']}`}></i>
                    </CopyToClipboard>
                </p>
                <p className={`${styles['qr-detail-item']} my-3`}><span>About:</span> {data.about}</p>
                <div className={styles['qr-detail-section']}>
                    <p className={styles['qr-detail-item']}><span>Title:</span> {data.title}</p>
                    <p className={styles['qr-detail-item']}><span>Name:</span> {data.name}</p>
                    <p className={styles['qr-detail-item']}><span>Designation:</span> {data.designation ? data.designation : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Organization:</span> {data.organization ? data.organization : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Website:</span> {data.website ? data.website : <i>{empty}</i>}</p>
                </div>
                <div className={styles['qr-detail-section-heading']}>Contact information</div>
                <div className={styles['qr-detail-section']}>
                    <p className={styles['qr-detail-item']}><span>Phone(Work):</span> {data.phoneWork ? data.phoneWork : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Phone(Private):</span> {data.phonePrivate ? data.phonePrivate : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Landline:</span> {data.landline ? data.landline : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Address:</span> {data.address ? data.address : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Fax:</span> {data.fax ? data.fax : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Email:</span> {data.email ? data.email : <i>{empty}</i>}</p>
                </div>
                <div className={styles['qr-detail-section-heading']}>Social media</div>
                <div className={styles['qr-detail-section']}>
                    <p className={styles['qr-detail-item']}><span>Facebook:</span> {data.socialMedia?.facebook ? data.socialMedia.facebook : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Twitter:</span> {data.socialMedia?.twitter ? data.socialMedia.twitter : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Instagram:</span> {data.socialMedia?.instagram ? data.socialMedia.instagram : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Youtube:</span> {data.socialMedia?.youtube ? data.socialMedia.youtube : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Linkedin:</span> {data.socialMedia?.linkedin ? data.socialMedia.linkedin : <i>{empty}</i>}</p>
                </div>
                <div className={styles['qr-detail-section-heading']}>QR detail</div>
                <div className={styles['qr-detail-section']}>
                    <p className={styles['qr-detail-item']}><span>Dark color:</span> {data.color.dark}</p>
                    <p className={styles['qr-detail-item']}><span>Light color:</span> {data.color.light}</p>
                    <p className={styles['qr-detail-item']}><span>Quality:</span> {data.quality}</p>
                    <p className={styles['qr-detail-item']}><span>Total scans:</span> {data.totalScans ? data.totalScans : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Unique views:</span> {data.uniqueViews ? data.uniqueViews : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Created at:</span> {data.createdAt ? `${new Date(data.createdAt)}`.substr(0,24) : <i>{empty}</i>}</p>
                    <p className={styles['qr-detail-item']}><span>Updated at:</span> {data.updatedAt ? `${new Date(data.updatedAt)}`.substr(0,24) : <i>{empty}</i>}</p>
                </div>
            </div>
        }
    </>)
}