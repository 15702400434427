import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';

export default function Sidebar(){
    const darkColor = '#004367';
    const lightColor = '#FFFFFF';
    const [ page, setPage ] = useState('');

    useEffect(() => {
        setPage(window.location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname])

    return (<>
        <div className={styles['sidebar']}>
            <div className={styles['logo']}>
                <img src="../assets/images/logo.png" alt="logo" /> <span className={styles['beta']}>Beta</span>
            </div>
            <div className={styles['sidemenu']}>
                <ul>
                    <li>
                        <NavLink to="/my-qr-codes" className={styles[(page === '/my-qr-codes' || page === '/qr-code-detail') ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_45_39" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_45_39)">
                                    <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill={(page === '/my-qr-codes' || page === '/qr-code-detail') ? lightColor : darkColor}/>
                                    <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill={(page === '/my-qr-codes' || page === '/qr-code-detail') ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span>My QR <i>Codes</i></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/create-qr-code" className={styles[page === '/create-qr-code' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
                                <mask id="mask0_20_139" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_20_139)">
                                    <path d="M5 14V12H7V14H5ZM3 12V10H5V12H3ZM12 5V3H14V5H12ZM4.5 7.5H7.5V4.5H4.5V7.5ZM3 9V3H9V9H3ZM4.5 19.5H7.5V16.5H4.5V19.5ZM3 21V15H9V21H3ZM16.5 7.5H19.5V4.5H16.5V7.5ZM15 9V3H21V9H15ZM9 14V12H7V10H11V14H9ZM10 9V5H12V7H14V9H10ZM16 21V18H13V16H16V13H18V16H21V18H18V21H16Z" fill={page === '/create-qr-code' ? lightColor : darkColor}/>
                                </g>
                            </svg>
                            <span>Create QR <i>Code</i></span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile" className={styles[page === '/profile' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
                                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
                                <path d="M20 20v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                            </svg>
                            <span>Profile</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/logout" className={styles[page === '/logout' ? 'active' : '']}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M10.09 15.59L8.67 17l-5-5 5-5 1.41 1.41L6.83 11H18v2H6.83l3.26 3.29zM20 3h-8c-1.1 0-2 .9-2 2v4h2V5h8v14h-8v-4h-2v4c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
                            </svg>
                            <span>Logout</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    </>)
}
