import axios from 'axios';
import { getToken } from '../Utils/Common';
export const baseurl = process.env.REACT_APP_BASEURL;

const tokenapi = axios.create({
    baseURL: baseurl,
    headers: {
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

tokenapi.interceptors.request.use(
    (config) => {
        const token = getToken();
        if(token) {
            config.headers.token = token;
        }
        return config;
    },
    (error) => {
        console.error("Error while fetching token.")
        return Promise.reject(error);
    }
);

export default tokenapi;

// Create QR code for website
export const createWebsiteQR = '/qrCode/url';

// Create QR code for v-card
export const createVcardQR = '/qrCode/vCardGen';

// Get QR url
export const getQrUrl = '/user/getQrUrl';

// Get list of all QR codes created with pagenumber in params
export const getQrList = '/user/getQrList/?pageNumber=';

// Get user by id
export const getUserById = '/getUserById';

// Reset password
export const resetPassword = '/user/resetPassword';

// Set password
export const setPassWord = '/user/resetPasswordGoogle';

// Fetch list of categories
export const getCategories = '/user/getCategories';

// Delete QR by id
export const deleteQrById = '/user/deleteQrById/';

// Fetch visitors details
export const getVisitors = '/user/getVisitors/';

// Edit details of QR generated for url
export const updateWebQr = '/user/updateWebQr';

// Edit details of QR generated for Vcard
export const updateVCard = '/user/updateVCard';

// Uplaod image to s3Bucket (Works with both user and admin token)
export const uploadImg = '/user/uploadImg';

// Fetch count qr by date
export const getCountQrByDate = '/user/getCountQrByDate/';

// View details of QR analytics
export const qrAnalytics = '/user/analytics/';

// Generate image of QR generated
export const generateImage = '/user/generateImage';

// Save QR code of URL
export const createUrlQr = '/user/createUrlQr';

// Edit details of QR generated for url
export const updateUrlQr = '/user/updateUrlQr';