import React from 'react';
import Sidebar from '../../Admin/Components/Sidebar/Sidebar';
import styles from './admin-layout.module.css';

export default function AdminLayout({children}){
    return (<>
        <div className={styles['dashboard']}>
            <Sidebar />
            <div className={styles['dashboard-content']}>
                <main>{children}</main>
            </div>
        </div>
    </>)
}
