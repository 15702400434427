import axios from 'axios';
import { getAdminToken } from '../Utils/Common';
export const baseurl = process.env.REACT_APP_BASEURL;

const adminapi = axios.create({
    baseURL: baseurl,
    headers: {
        'versionnumber': process.env.REACT_APP_VERSION
    },
});

adminapi.interceptors.request.use((config) => {
    const token = getAdminToken();
    if(token) {
        config.headers.token = token;
    }
    return config;
},
(error) => {
    console.error("Error while fetching token.")
    return Promise.reject(error);
});

export default adminapi;

// Fetch list of all registered users
export const fetchAllUsers = '/all/users';

// Delete user from admin
export const deleteUserFromAdmin = '/admin/deleteAccountUser/';

// fetch list of all categories for admin
export const getCategories = '/admin/getCategories';

// Delete category from admin
export const deleteCategory = '/admin/deleteCategory/';

// Create category from admin
export const createCategory = '/admin/createCategory';

// Update category from admin
export const updateCategory = '/admin/updateCategory';

// Get list of all QR codes created with userId and pageNumber in params
export const getQrList = '/admin/getQrListUserId/';

// This APi is to resend email verification mail to the user
export const resendVerification = '/admin/resendVerification';