import React from 'react';
import { Helmet } from 'react-helmet';
import CreateManageQR from '../Components/CreateManageQR/CreateManageQR';
import styles from './about.module.css';

export default function About(){
    return (<>
        <Helmet>
            <title>QR code Generator | yearly subscription plan</title>
            <meta name="description" content="We are dedicated to providing top-notch free QR code generator solutions that are easy to use. Our mission is to simplify digital interactions." />
        </Helmet>
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>With great data comes great responsibility</h1>
                    </div>
                    <div>
                        <img src="../assets/images/about-banner.png" alt="" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['about']}>
                    <div>
                        <img src="../assets/images/about.png" alt="" width="100%" />
                    </div>
                    <div>
                        <h6>Welcome to scanonn</h6>
                        <h2>your go-to source for QR code generation and tracking solutions</h2>
                        <p>Our mission is to provide businesses and individuals with an easy and reliable way to track data, improve efficiency, and streamline processes. With our state-of-the-art QR code generator, you can create custom codes in seconds and track valuable data in real-time. Our team of experienced professionals is dedicated to delivering exceptional service and support, and we are committed to helping our clients achieve their goals. Whether you are a small business owner, a marketing professional, or a healthcare provider, we have the tools and expertise you need to succeed.</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className={styles['vision-mission']}>
                    <div>
                        <h2>Vision:</h2>
                        <p>At Scanonn, we envision a world where technology is simple, intuitive, and accessible to everyone. We believe that by providing easy-to-use and updated technologies, we can help businesses and individuals achieve their goals, streamline processes, and drive innovation.</p>
                        <h2>Mission:</h2>
                        <p>Our mission is to provide businesses and individuals with a simple and reliable way to generate and track QR codes.</p>
                    </div>
                    <div>
                        <img src="../assets/images/vission-mission.png" alt="" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <CreateManageQR />
        <section>
            <div className='container'>
                <div className={styles['our-founders']}>
                    <div>
                        <img src="../assets/images/founder.png" alt="" width="100%" />
                    </div>
                    <div>
                        <h2>Our Founders</h2>
                        <p>Welcome to our QR code generator startup! Our company was founded by two tech enthusiasts who share a passion for simplifying the way people access and track information through QR codes.</p>
                        {/* <h2>Meet Our Co-Founders</h2>
                        <p>
                            One of our co-founders is inspired by the Spartan warrior King Leonidas and is dedicated to following a similar code of ethics and always staying true to their values, both personally and professionally. You can reach Leonidas at leonidas@Scanonn.com.<br/>
                            The other co-founder admires Bruce Wayne, aka Batman, and applies their intelligence, resourcefulness, and strategic thinking to their work in our company, always looking for innovative solutions and ways to improve our products and services. You can reach Batman at batman@Scanonn.com.<br/>
                            Together, our co-founders combine their unique interests and strengths to create a company that is dedicated to providing simple and updated technologies in the world of QR codes. We believe that QR codes are the future of information access and tracking, and we’re excited to be a part of this growing industry.<br/>
                            Thank you for considering our company, and please don’t hesitate to reach out to us if you have any questions or if you’re interested in our QR code solutions.
                        </p> */}
                    </div>
                </div>
            </div>
        </section>
    </>)
}
