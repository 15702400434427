export const addHttps = (url) => {
    if(url){
        if(url.substr(0, 4) === 'http'){
            return url;
        } else {
            return 'https://' + url;
        }
    } else {
        return '';
    }
}