import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeSession } from '../../Utils/Common';
import styles from "./logout.module.css";

export default function Logout(){
    const navigate = useNavigate();
    
    useEffect(() => {
        removeSession();
        navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <div className={styles['logout']}>
            <h1>Logging out</h1>
            <h2>Please wait ...</h2>
        </div>
    </>)
}
