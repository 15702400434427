import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api, { verifyEmail } from '../../Base/Config';
import styles from './action-pages.module.css';

export default function VerifyEmail(){
    const navigate = useNavigate();
    const string = useParams().string;
    const [ loading, setLoading ] = useState(true);
    const [ status, setStatus ] = useState('Wait...');

    useEffect(() => {
        submitFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitFunction = () => {
        api.post(verifyEmail, {
            token: string
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setStatus('Email verified. Now you can login to your dashboard.');
                navigate('/login');
            } else {
                setStatus('fail');
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    return (<>
        <div className={styles['action-page']}>
            {
                loading
                ?<img src="../assets/images/loader.gif" alt="loader" />
                :status
            }
        </div>
    </>)
}
