import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { getVcard } from '../../Base/Config';
import Message from '../../Layouts/Message/Message';
import styles from './qr-details.module.css';

export default function VcardDetails(){
    const string = useParams().string;
    const [details, setDetails] = useState('');
    const [socialMediaCount, setSocialMediaCount] = useState(0);
    const [loader, setLoader] = useState(true);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    useEffect(() => {
        fetchFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = () => {
        api.get(getVcard + string)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200) {
                setDetails(res.data.result);
                const socialMediaValues = Object.values(res.data.result.socialMedia);
                const filterArray = socialMediaValues.filter(item => item !== '');
                setSocialMediaCount(filterArray.length);
            } else {
                console.log(res);
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
            setMessageType('error');
            setMessage('Please try again, Sorry for the trouble.');
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {
            loader
            ?<div className={styles['loader']}>
                <img src="../assets/images/loader.gif" alt="loader" style={{width:'100px'}} />
            </div>
            :<div className={styles['vcard-container']}>
                <div className={styles['vcard-header']}>
                    <img src="../assets/images/logo.png" alt="logo" />
                </div>
                <div className={styles['user-details']}>
                    {
                        details.photo
                        ?<img src={details.photo} alt={details.name} />
                        :<img src={`https://robohash.org/${details.requestId}`} alt={details.name} />
                    }
                    <div>
                        <h1>{details.name}</h1>
                        {details.designation && <h2>{details.designation}</h2>}
                    </div>
                </div>
                <div className={styles['vcard-details']}>
                    {details.about && <p><span>About:</span> {details.about}</p>}
                    {details.organization && <p><span>Organization:</span> {details.organization}</p>}
                    {details.phoneWork && <p><span>Phone (Work):</span> <a href={`tel:${details.phoneWork}`}>{details.phoneWork}</a></p>}
                    {details.phonePrivate && <p><span>Phone (Private):</span> <a href={`tel:${details.phonePrivate}`}>{details.phonePrivate}</a></p>}
                    {details.landline && <p><span>Landline:</span> <a href={`tel:${details.landline}`}>{details.landline}</a></p>}
                    {details.fax && <p><span>Fax:</span> <a href={`fax:${details.fax}`}>{details.fax}</a></p>}
                    {details.email && <p><span>Email:</span> <a href={`mailto:${details.email}`}>{details.email}</a></p>}
                    {details.website && <p><span>Website:</span> {details.website} <a href={details.website} target="_blank" rel="noreferrer"><i className="fa fa-external-link"></i></a></p>}
                    {details.address && <p><span>Address:</span> {details.address}</p>}
                </div>
                <div className={styles['social-media']} style={{gridTemplateColumns: `repeat(${socialMediaCount}, 1fr)`}}>
                    {details.socialMedia?.facebook && <a href={details.socialMedia.facebook} target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>}
                    {details.socialMedia?.twitter && <a href={details.socialMedia.twitter} target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>}
                    {details.socialMedia?.linkedin && <a href={details.socialMedia.linkedin} target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>}
                    {details.socialMedia?.instagram && <a href={details.socialMedia.instagram} target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>}
                    {details.socialMedia?.youtube && <a href={details.socialMedia.youtube} target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a>}
                </div>
            </div>
        }
    </>)
}
