import React, { useState } from 'react';
import tokenapi, { createVcardQR, uploadImg } from '../../../../Base/TokenConfig';
import { addHttps } from '../../../../Utils/addHttps';
import { qualityOptions } from '../Common';
import Message from '../../../../Layouts/Message/Message';
import Modal from '../../../../Layouts/Modal/Modal';
import styles from '../../create-qr-code.module.css';

export default function VCard(props) {
    const [title, setTitle] = useState('');
    const [website, setWebsite] = useState('');
    const [darkColor, setDarkColor] = useState('#000000');
    const [lightColor, setLightColor] = useState('#FFFFFF');
    const [quality, setQuality] = useState('');
    const [name, setName] = useState('');
    const [about, setAbout] = useState('');
    const [organization, setOrganization] = useState('');
    const [designation, setDesignation] = useState('');
    const [phoneWork, setPhoneWork] = useState('');
    const [phonePrivate, setPhonePrivate] = useState('');
    const [landline, setLandline] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [fileUploading, setFileUploading] = useState(false);
    const [photo, setPhoto] = useState('');
    const [facebook, setFacebook] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [detailModal, setDetailModal] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const uploadFunction = (file) => {
        setFileUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        tokenapi.post(uploadImg, formData)
        .then(res => {
            setFileUploading(false);
            if(res.data.code === 200) {
                setPhoto(res.data.result);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setFileUploading(false);
            console.error(err);
        })
    }

    const createFunction = (e) => {
        e.preventDefault();
        tokenapi.post(createVcardQR, {
            title: title,
            name: name,
            about: about,
            organization: organization,
            designation: designation,
            phoneWork: phoneWork,
            phonePrivate: phonePrivate,
            landline: landline,
            fax: fax,
            email: email,
            website: addHttps(website),
            address: address,
            photo: photo,
            quality: quality,
            color: {
                dark: darkColor,
                light: lightColor
            },
            socialMedia: {
                facebook: addHttps(facebook),
                twitter: addHttps(twitter),
                linkedin: addHttps(linkedin),
                instagram: addHttps(instagram),
                youtube: addHttps(youtube)
            },
            categoryId: props.categoryId
        })
        .then(res => {
            if(res.data.code === 200){
                setQrCode(res.data.result.S3Url);
                setDetailModal(true);
                setMessageType('success');
                setMessage(res.data.message);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const closeModal = () => {
        props.stepChangeFunction(1);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {detailModal && <Modal closeModal={() => closeModal()}>
            <div className={styles["modal-box"]}>
                <div className={styles['modal-heading']}>Details</div>
                <div className={styles["modal-content"]}>
                    <div>
                        <img src={qrCode} style={{borderColor:`${darkColor}`}} alt="qr" />
                    </div>
                    <div>
                        <p><span>Title</span> {title}</p>
                        <p><span>About</span> {about}</p>
                        <p><span>Quality</span> {quality}</p>
                        <p><span>Dark color</span> <span className={styles['color-box']}><input type="color" defaultValue={darkColor} disabled/>{darkColor}</span></p>
                        <p><span>Light color</span> <span className={styles['color-box']}><input type="color" defaultValue={lightColor} disabled/>{lightColor}</span></p>
                    </div>
                </div>
                <button className="btn btn-secondary mt-3" onClick={() => closeModal()}>Close</button>
            </div>
        </Modal>}
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Personal information</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Name*</label>
                    <input type="text" placeholder="Enter name" value={name} onChange={e => setName(e.target.value)} required/>
                </div>
                <div>
                    <label>About*</label>
                    <input type="text" placeholder="Enter about" value={about} onChange={e => setAbout(e.target.value)} required/>
                </div>
                <div>
                    <label>Organization</label>
                    <input type="text" placeholder="Enter organization" value={organization} onChange={e => setOrganization(e.target.value)} />
                </div>
                <div>
                    <label>Designation</label>
                    <input type="text" placeholder="Enter designation" value={designation} onChange={e => setDesignation(e.target.value)} />
                </div>
                <div>
                    <label>Photograph</label>
                    {
                        fileUploading
                        ?<div className={styles['uploading-file']}><i className="fa fa-circle-o-notch fa-spin"></i> Uploading file</div>
                        :<>
                            {
                                photo
                                ?<input type="text" defaultValue="File uploaded" disabled/>
                                :<input type="file" accept=".png,.jpg,.jpeg" onChange={e => uploadFunction(e.target.files[0])} />
                            }
                        </>
                    }
                </div>
                <div>
                    <label>Website URL</label>
                    <input type="text" placeholder="Enter website url" value={website} onChange={e => setWebsite(e.target.value)} />
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Contact information</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Phone (work)</label>
                    <input type="text" placeholder="Enter phone" value={phoneWork} onChange={e => setPhoneWork(e.target.value)} />
                </div>
                <div>
                    <label>Phone (Private)</label>
                    <input type="text" placeholder="Enter phone" value={phonePrivate} onChange={e => setPhonePrivate(e.target.value)} />
                </div>
                <div>
                    <label>Landline</label>
                    <input type="text" placeholder="Enter landline" value={landline} onChange={e => setLandline(e.target.value)} />
                </div>
                <div>
                    <label>Fax</label>
                    <input type="text" placeholder="Enter fax" value={fax} onChange={e => setFax(e.target.value)} />
                </div>
                <div>
                    <label>Email</label>
                    <input type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div>
                    <label>Address</label>
                    <input type="text" placeholder="Enter address" value={address} onChange={e => setAddress(e.target.value)} />
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Social media</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Facebook</label>
                    <input type="text" placeholder="Enter facebook link" value={facebook} onChange={e => setFacebook(e.target.value)} />
                </div>
                <div>
                    <label>Twitter</label>
                    <input type="text" placeholder="Enter twitter link" value={twitter} onChange={e => setTwitter(e.target.value)} />
                </div>
                <div>
                    <label>Linkedin</label>
                    <input type="text" placeholder="Enter linkedin link" value={linkedin} onChange={e => setLinkedin(e.target.value)} />
                </div>
                <div>
                    <label>Instagram</label>
                    <input type="text" placeholder="Enter instagram link" value={instagram} onChange={e => setInstagram(e.target.value)} />
                </div>
                <div>
                    <label>Youtube</label>
                    <input type="text" placeholder="Enter youtube link" value={youtube} onChange={e => setYoutube(e.target.value)} />
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>QR code details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Dark color*</label>
                    <div className={styles['color-inputs']}>
                        <input type="color" value={darkColor} onChange={e => setDarkColor(e.target.value)} required/>
                        <span></span>
                        <input type="text" placeholder="Enter website url" value={darkColor} onChange={e => setDarkColor(e.target.value)} required/>
                    </div>
                </div>
                <div>
                    <label>Light color*</label>
                    <div className={styles['color-inputs']}>
                        <input type="color" value={lightColor} onChange={e => setLightColor(e.target.value)} required/>
                        <span></span>
                        <input type="text" placeholder="Enter website url" value={lightColor} onChange={e => setLightColor(e.target.value)} required/>
                    </div>
                </div>
                <div>
                    <label>Quality*</label>
                    <select value={quality} onChange={e => setQuality(e.target.value)} required>
                        <option value="">-- Select any one --</option>
                        {qualityOptions.map((item, i) => (
                            <option value={item} key={i}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
    </>)
}