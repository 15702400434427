import React, { useEffect, useState } from 'react';
import Sidebar from '../../User/Components/Sidebar/Sidebar';
import Modal from '../Modal/Modal';
import styles from './user-layout.module.css';

export default function UserLayout({children}){
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if(localStorage.getItem('beta')){
            setModal(true);
        }
    }, [])

    const closeModal = () => {
        setModal(false);
        localStorage.removeItem('beta')
    }

    return (<>
        {modal && <Modal closeModal={() => closeModal()}>
            <div className={styles['beta-version-modal']}>
                <h2>Beta Version Notice</h2>
                <div className={styles['beta-version-content']}>
                    <p><b>Welcome to ScanOnn!</b></p>
                    <p>Please note that this website is currently in its beta version. While we are excited to offer you a preview of our QR code generator, some features may not be fully developed, and you might encounter occasional bugs or incomplete functionalities.</p>
                    <p>We highly appreciate your understanding and patience as we work to improve the platform. Your feedback is invaluable to us. If you encounter any issues or have any suggestions, please do not hesitate to contact us at: <a href="mailto:support@scanonn.com">support@scanonn.com</a></p>
                    <p>Thank you for being a part of our beta testing phase and helping us make ScanOnn better!</p>
                </div>
                <button className="btn btn-primary" onClick={() => closeModal()}>Okay</button>
            </div>
        </Modal>}
        <div className={styles['dashboard']}>
            <Sidebar />
            <div className={styles['dashboard-content']}>
                <main>{children}</main>
            </div>
        </div>
    </>)
}
