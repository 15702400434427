import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenapi, { getCategories } from '../../../Base/TokenConfig';
import { removeCategory, setCategory } from '../../../Utils/Common';
import styles from '../create-qr-code.module.css';

export default function ChooseCategory(props){
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        removeCategory();
        fetchCategories();
        sessionStorage.removeItem('qr-content');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = () => {
        tokenapi.get(getCategories)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200){
                setCategories(res.data.result);
            } else if(res.data.code === 401) {
                navigate('/logout');
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const selectCategory = (x) => {
        setCategory(JSON.stringify(x));
        props.stepChangeFunction(2);
    }

    return (<>
        {
            loader
            ?
                <div className={styles['qr-categories']}>
                    <div className={`${styles['qr-category']} ${styles['qr-category-loader']}`}></div>
                    <div className={`${styles['qr-category']} ${styles['qr-category-loader']}`}></div>
                </div>
            :<>
                <div className={styles['qr-categories']}>
                    {categories.map((item, i) => (
                        <div className={styles[item.isActive ? 'qr-category' : 'disabled-qr-category']} onClick={() => selectCategory(item)} key={i}>
                            <div className={styles[item.isActive ? 'qr-category-image' : 'disabled-qr-category-image']}>
                                <img src={item.categoryIcon} alt="category-icon" />
                            </div>
                            <div className={styles['qr-category-info']}>
                                <p>{item.categoryName}</p>
                                <span>{item.categoryDescription}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        }
    </>)
}
