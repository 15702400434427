import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminapi, { fetchAllUsers } from '../../Base/AdminConfig';
import { setQrDetail } from '../../Utils/Common';
import styles from './users.module.css';

export default function Users(){
    const navigate = useNavigate();
    const [ users, setUsers ] = useState([]);

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchUsers = () => {
        adminapi.get(fetchAllUsers)
        .then(res => {
            if(res.data.code === 200){
                setUsers(res.data.result);
            } else {
                setUsers([]);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    function openDetail(item) {
        setQrDetail(item);
        navigate('/admin/user-detail');
    }

    return (<>
        <h1>Users <span>({users.length})</span></h1>
        {
            users.length > 0
            ?<div className={styles['users-list']}>
                <div>
                    <div><p>#</p></div>
                    <div className={styles['main-details']}>
                        <p>Created on</p>
                        <p>Name</p>
                        <p>Email</p>
                        <p>Type</p>
                        <p>Status</p>
                    </div>
                    <div><p>Details</p></div>
                </div>
                {users.map((item, i) => (
                    <div key={item._id}>
                        <div><p><span>#</span> {i + 1}</p></div>
                        <div className={styles['main-details']}>
                            <p><span>Created on</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                            <p><span>Name</span> {item.firstName + " " + item.lastName}</p>
                            <p><span>Email</span> {item.email}</p>
                            <p><span>Type</span> {item.type ? item.type : '_-_-_-_'}</p>
                            <p className={styles[item.isActive ? 'active' : 'inactive']}><span>Status</span> {item.isActive ? 'active' : 'inactive'}</p>
                        </div>
                        <div><p className={styles['details']} onClick={() => openDetail(item)}>Details</p></div>
                    </div>
                ))}
            </div>
            :<div>
                No users found
            </div>
        }
    </>)
}