import React from 'react';
import Navbar from '../../Pages/Components/Navbar/Navbar';
import Footer from '../../Pages/Components/Footer/Footer';

export default function PageLayout({ children }){
    return (<>
        <Navbar />
        <main>{children}</main>
        <Footer />
    </>)
}
