import React, { useEffect, useState } from 'react';
import tokenapi, { getCountQrByDate, qrAnalytics } from '../../../Base/TokenConfig';
import { PieChart, Pie, Tooltip, ResponsiveContainer, AreaChart, XAxis, YAxis, CartesianGrid, Area, Cell } from 'recharts';
import styles from '../my-qr-code-detail.module.css';

export default function QRAnalytics(props){
    const [loader, setLoader] = useState(true);
    const [scanCounts, setScanCounts] = useState([]);
    const [deviceStats, setDeviceStats] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const colors = ["#345995", "#03CEA4", "#FB4D3D", "#CA1551", "#EAC435"];

    useEffect(() => {
        fetchGraphData(props.data.requestId);
        fetchFunction(props.data.requestId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchGraphData = (id) => {
        tokenapi.get(getCountQrByDate + id)
        .then(res => {
            if(res.data.code === 200) {
                setScanCounts(res.data.result);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const fetchFunction = (id) => {
        setLoader(true);
        tokenapi.get(qrAnalytics + id)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200) {
                setDeviceStats(res.data.result.deviceStats);
                setCityData(res.data.result.cityData);
                setCountryData(res.data.result.countryData);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const CustomTooltip = ({ payload }) => {
        if(payload && payload.length) {
            const data = payload[0].payload.payload;
            return (
                <div className={styles['custom-tooltip']}>
                    <p><span>Device type:</span> {data.deviceType}</p>
                    <p><span>Count:</span> {data.count}({data.percentage.toFixed(2)}%)</p>
                </div>
            );
        }
        return null;
    };
    
    return (<>
        {
            loader
            ?<>
                <div className={styles['loader-box']}></div>
                <div className={styles['analytics-cards']}>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                    <div className={styles['loader-box']}></div>
                </div>
            </>
            :<>
                <div className={styles['analytics-card']}>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart width={500} height={300} data={scanCounts} margin={{ top: 0, right: 10, left: -20, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#08A4F7" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#08A4F7" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="_id"/>
                            <YAxis/>
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Area type="monotone" dataKey="count" stroke="#004367" fillOpacity={1} fill="url(#colorUv)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className={styles['analytics-cards']}>
                    <div className={styles['analytics-card']}>
                        <h5>Scans by operating system</h5>
                        <ResponsiveContainer width="100%" height={220}>
                            <PieChart width={250} height={250}>
                                <Pie data={deviceStats} dataKey="count" nameKey="deviceType" cx="50%" cy="50%" innerRadius={50} outerRadius={80}>
                                    {deviceStats.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index]}/>
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                        <div className={styles['graph-data']}>
                            {deviceStats.map((item, i) => (
                                <p key={i}><span style={{backgroundColor: colors[i]}}></span> {item.deviceType} - {item.count}</p>
                            ))}
                        </div>
                    </div>
                    <div className={styles['analytics-card']}>
                        <h5>City count</h5>
                        <ResponsiveContainer width="100%" height={220}>
                            <PieChart width={250} height={250}>
                                <Pie data={cityData} dataKey="count" nameKey="percentage" cx="50%" cy="50%" innerRadius={50} outerRadius={80}>
                                    {cityData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className={styles['graph-data']}>
                            {cityData.map((item, i) => (
                                <p key={i}><span style={{backgroundColor: colors[i]}}></span> {item.city} - {item.count}</p>
                            ))}
                        </div>
                    </div>
                    <div className={styles['analytics-card']}>
                        <h5>Country count</h5>
                        <ResponsiveContainer width="100%" height={220}>
                            <PieChart width={250} height={250}>
                                <Pie data={countryData} dataKey="count" nameKey="percentage" cx="50%" cy="50%" innerRadius={50} outerRadius={80}>
                                    {countryData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className={styles['graph-data']}>
                            {countryData.map((item, i) => (
                                <p key={i}><span style={{backgroundColor: colors[i]}}></span> {item.country} - {item.count}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        }
    </>)
}