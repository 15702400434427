import React, { useEffect, useState } from 'react';
import tokenapi, { uploadImg } from '../../Base/TokenConfig';
import adminapi, { getCategories, deleteCategory, createCategory, updateCategory } from '../../Base/AdminConfig';
import Message from '../../Layouts/Message/Message';
import Modal from '../../Layouts/Modal/Modal';
import styles from './qr-category.module.css';

export default function QRCategory(){
    const [addModal, setAddModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [detail, setDetail] = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [categoryType, setCategoryType] = useState('');
    const [categoryIcon, setCategoryIcon] = useState('');
    const [editCategoryIcon, setEditCategoryIcon] = useState('');

    const uploadFunction = (file) => {
        const formData = new FormData();
        formData.append("file", file);
        tokenapi.post(uploadImg, formData)
        .then(res => {
            if(res.data.code === 200) {
                setCategoryIcon(res.data.result);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    useEffect(() => {
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchCategories = () => {
        adminapi.get(getCategories)
        .then(res => {
            if(res.data.code === 200) {
                setCategories(res.data.result);
            } else {
                setCategories([]);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const openModal = (x) => {
        setDetailModal(true);
        setDetail(x);
        setCategoryId(x.categoryId);
        setCategoryName(x.categoryName);
        setCategoryDescription(x.categoryDescription);
        setCategoryType(x.categoryType);
        setEditCategoryIcon(x.categoryIcon);
    }

    const closeModal = () => {
        setDetailModal(false);
        setAddModal(false);
        clearInput();
    }

    const clearInput = () => {
        setCategoryId('');
        setCategoryName('');
        setCategoryDescription('');
        setCategoryType('');
        setCategoryIcon('');
        setEditCategoryIcon('');
    }

    const createCategoryFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        adminapi.post(createCategory, {
            categoryName: categoryName,
            categoryDescription: categoryDescription,
            categoryType: categoryType,
            categoryIcon: categoryIcon
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchCategories();
                setAddModal(false);
                clearInput();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const updateCategoryFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        adminapi.put(updateCategory, {
            categoryId: categoryId,
            categoryName: categoryName,
            categoryDescription: categoryDescription,
            categoryType: categoryType,
            categoryIcon: categoryIcon ? categoryIcon : editCategoryIcon
        })
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchCategories();
                setDetailModal(false);
                clearInput();
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const deleteCategoryFunction = (detail) => {
        setLoading(true);
        adminapi.delete(deleteCategory + detail._id)
        .then(res => {
            setLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200) {
                fetchCategories();
                setDetailModal(false);
                setMessageType('success');
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {addModal && <Modal closeModal={closeModal}>
            <div className={styles["modal-content"]}>
                <div className={styles['modal-heading']}>Add category <img src="../assets/images/times.png" onClick={() => closeModal()} alt="times" /></div>
                <form onSubmit={createCategoryFunction}>
                    <label>Category name</label>
                    <input type="text" placeholder="Enter category name here" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} required/>
                    <label>Category description</label>
                    <input type="text" placeholder="Enter category description here" value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} required/>
                    <label>Category type</label>
                    <input type="text" placeholder="Enter category type here" value={categoryType} onChange={(e) => setCategoryType(e.target.value)} required/>
                    <label>Category icon</label>
                    {
                        categoryIcon
                        ?<input type="text" defaultValue="File uploaded" disabled/>
                        :<input type="file" accept=".png,.jpg,.jpeg" onChange={e => uploadFunction(e.target.files[0])} />
                    }
                    <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        {detailModal && <Modal closeModal={closeModal}>
            <div className={styles["modal-content"]}>
                <div className={styles['modal-heading']}>Category details <img src="../assets/images/times.png" onClick={() => closeModal()} alt="times" /></div>
                <div className={styles['category-details']}>
                    <div className={styles['category-icon']}>
                        <img src={detail.categoryIcon} alt="icon" />
                    </div>
                    <div>
                        <p><span>Id</span> {detail.categoryId}</p>
                        <p><span>Created at</span> {`${new Date(detail.createdAt)}`.substr(0, 24)}</p>
                        <p><span>Status</span> {detail.isActive ? 'active' : 'inactive'}</p>
                        <p onClick={() => deleteCategoryFunction(detail)} className={styles['delete-category']}><span className="fa fa-trash-o"></span> Delete category</p>
                    </div>
                </div>
                <form onSubmit={updateCategoryFunction}>
                    <label>Category name</label>
                    <input type="text" placeholder="Enter category name here" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} required/>
                    <label>Category description</label>
                    <input type="text" placeholder="Enter category description here" value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} required/>
                    <label>Category type</label>
                    <input type="text" placeholder="Enter category type here" value={categoryType} onChange={(e) => setCategoryType(e.target.value)} required/>
                    <label>Category Icon</label>
                    {
                        categoryIcon
                        ?<input type="text" defaultValue="File uploaded" disabled/>
                        :<input type="file" accept=".png,.jpg,.jpeg" onChange={e => uploadFunction(e.target.files[0])} />
                    }
                    <button className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        <h1>QR Category <span>({categories.length})</span></h1>
        <button className="btn btn-primary mb-3" onClick={() => setAddModal(true)}>Add category</button>
        <div className={styles['category-list']}>
            <div>
                <div><p>#</p></div>
                <div className={styles['main-details']}>
                    <p>Name</p>
                    <p>Id</p>
                    <p>Description</p>
                    <p>Status</p>
                </div>
                <div><p>Details</p></div>
            </div>
            {categories.map((item, i) => (
                <div key={item._id}>
                    <div><p><span>#</span> {i + 1}</p></div>
                    <div className={styles['main-details']}>
                        <p><span>Name</span> {item.categoryName}</p>
                        <p><span>Id</span> {item.categoryId}</p>
                        <p><span>Description</span> {item.categoryDescription}</p>
                        <p className={styles[item.isActive ? 'active' : 'inactive']}><span>Status</span> {item.isActive ? 'active' : 'inactive'}</p>
                    </div>
                    <div><p className={styles['details']} onClick={() => openModal(item)}>Details</p></div>
                </div>
            ))}
        </div>
    </>)
}