import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import api, { contactUsQuery } from '../../Base/Config';
import Message from '../../Layouts/Message/Message';
import styles from './contact-us.module.css';

export default function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [service, setService] = useState('');
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [ messageType, setMessageType ] = useState('');
    const [ message, setMessage ] = useState('');

    const submitFunction = (e) => {
        e.preventDefault();
        setLoading(true);
        api.post(contactUsQuery, {
            name: name,
            email: email,
            phone: phone,
            organization: organization,
            service: service,
            comment: comment
        })
        .then(res => {
            if(res.data.code === 200) {
                setMessageType('success');
                emptyValues();
            } else {
                setMessageType('error');
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const emptyValues = () => {
        setName('');
        setEmail('');
        setPhone('');
        setOrganization('');
        setService('');
        setComment('');
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <Helmet>
            <title>Contact Us | ScanOnn | QR code maker</title>
            <meta name="description" content="We'd love to hear from you! Whether you have questions about our QR code generator, need assistance, or want to provide feedback, our team is here to help." />
        </Helmet>
        <section id={styles['banner']}>
            <div className="container">
                <div className={styles["banner"]}>
                    <div>
                        <h1>“Unlock the Power of Visual Communication, Contact Us Today!”</h1>
                    </div>
                    <div>
                        <img src="../assets/images/contact-banner.png" alt="" width="100%" />
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <h2 className={styles['heading']}>Let’s Start a Conversation</h2>
                <div className={styles['contact-us']}>
                    <div>
                        <img src="../assets/images/contact.png" alt="" width="100%" />
                        <p>We value your feedback, inquiries, and collaboration opportunities. Whether you have a question, suggestion, or simply want to reach out, this is the place to connect with us. Our dedicated team is here to assist you and ensure that your experience with us is smooth and satisfactory.</p>
                    </div>
                    <div>
                        <form onSubmit={submitFunction}>
                            <input type="text" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} required/>
                            <input type="text" placeholder="Email email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                            <input type="text" placeholder="Mobile number" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                            <input type="text" placeholder="Organisation"  value={organization} onChange={(e) => setOrganization(e.target.value)} required/>
                            <input type="text" placeholder="Select service" value={service} onChange={(e) => setService(e.target.value)} required/>
                            <textarea className="form-control" placeholder="Comment" value={comment} onChange={(e) => setComment(e.target.value)} required></textarea>
                            <button type="submit" className="btn btn-primary mt-3" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>)
}