export const downloadQr = (imageUrl) => {
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = imageUrl;

    // Set the download attribute with the desired file name
    link.download = 'qr.png';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
};