import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getQrDetail } from '../../Utils/Common';
import QrDetails from './Components/QrDetails';
import VisitorDetails from './Components/VisitorDetails';
import styles from './my-qr-code-detail.module.css';
import QRAnalytics from './Components/QRAnalytics';
import Navbar from '../Components/Navbar/Navbar';

export default function MyQRCodeDetail(){
    const navigate = useNavigate();
    const qrDetail = getQrDetail();
    const [tabName, setTabName] = useState('qr-details');

    useEffect(() => {
        if(!qrDetail) {
            navigate('/my-qr-codes');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <Navbar heading='QR Codes Detail' subheading='View QR and Visitor Details' />
        <div className="dashboard-content">
            <ul className={styles['tabs']}>
                <li className={styles[tabName === 'qr-details' ? 'selected' : '']} onClick={() => setTabName('qr-details')}>QR details</li>
                <li className={styles[tabName === 'visitor-details' ? 'selected' : '']} onClick={() => setTabName('visitor-details')}>Visitor details</li>
                <li className={styles[tabName === 'qr-analytics' ? 'selected' : '']} onClick={() => setTabName('qr-analytics')}>QR analytics</li>
            </ul>
            {tabName === 'qr-details' && <QrDetails data={qrDetail} />}
            {tabName === 'visitor-details' && <VisitorDetails data={qrDetail} />}
            {tabName === 'qr-analytics' && <QRAnalytics data={qrDetail} />}
        </div>
    </>)
}
