import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import api, { loginWithGoogle, userLogin, sendForgotPasswordEmail } from '../../../Base/Config';
import { setSession } from '../../../Utils/Common';
import Message from '../../../Layouts/Message/Message';
import Modal from '../../../Layouts/Modal/Modal';
import styles from "../login.module.css";

export default function Signin(props){
    const navigate = useNavigate();
    const captchaRef = useRef(null);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ forgotPasswordModal, setForgotPasswordModal ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ forgotPasswordEmail, setForgotPasswordEmail ] = useState('');
    const [ forgotPasswordLoading, setForgotPasswordLoading ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageType, setMessageType ] = useState('');
    const [ googleLoader, setGoogleLoader ] = useState(false);

    const setBetaStorage = () => {
        localStorage.setItem('beta', true);
    }
  
    const submitFunction = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if(token){
            loginFunction(token);
        } else {
            setMessageType('error');
            setMessage('Please fill the captcha first');
            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }

    const loginFunction = (token) => {
        setLoading(true);
        api.post(userLogin, {
            email: email,
            password: password,
            recaptchaToken: token
        })
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setSession(res.data.token);
                setBetaStorage();
                navigate('/my-qr-codes');
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
            setMessageType('error');
            setMessage(err.message);
        })
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async response => {
            try {
                googleAuth(response.access_token);
            } catch (error) {
                console.error(error);
            }
        }
    });

    const googleAuth = (token) => {
        setGoogleLoader(true);
        api.post(loginWithGoogle, {
            token: token
        })
        .then(res => {
            setGoogleLoader(false);
            if(res.data.code === 200){
                setSession(res.data.token);
                setBetaStorage();
                navigate('/my-qr-codes');
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setGoogleLoader(false);
            console.error(err);
        })
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setForgotPasswordLoading(true);
        api.post(sendForgotPasswordEmail, {
            email: forgotPasswordEmail
        })
        .then(res => {
            setForgotPasswordLoading(false);
            setMessage(res.data.message);
            if(res.data.code === 200){
                setMessageType('success');
                setForgotPasswordModal(false);
            } else {
                setMessageType('error');
            }
        })
        .catch(err => {
            setForgotPasswordLoading(false);
            console.error(err);
            setMessageType('error');
            setMessage(err.message);
        })
    }

    return (<>
        {googleLoader && <div className={styles['google-loader']}>
            <div>Logging in. Please wait.</div>
        </div>}
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {forgotPasswordModal && <Modal closeModal={() => setForgotPasswordModal(false)}>
            <div className={styles["forgot-password-modal"]}>
                <div className={styles['modal-heading']}>Forgot password? <img src="./assets/images/times.png" onClick={() => setForgotPasswordModal(false)} alt="times"/></div>
                <p>Please enter your email address to receive a link to create a new password via email.</p>
                <form onSubmit={sendEmail}>
                    <input type="email" placeholder="Enter email" value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)} required/>
                    <button className="btn btn-primary mt-3" disabled={forgotPasswordLoading}>{forgotPasswordLoading ? 'Wait ...' : 'Submit'}</button>
                </form>
            </div>
        </Modal>}
        <form onSubmit={submitFunction}>
            <h1>Welcome back</h1>
            <label>EMAIL ADDRESS</label>
            <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
            <label>PASSWORD</label>
            <div className={styles['hide-show']}>
                <input type={showPassword ? 'text' : 'password'} placeholder="Enter password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                <div onClick={() => setShowPassword(!showPassword)}><span className={`fa fa-${showPassword ? 'eye' : 'eye-slash'}`}></span></div>
            </div>
            <p className={styles['forgot-password']}><span onClick={() => setForgotPasswordModal(true)}>Forgot password?</span></p>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
            <br/>
            <button className="button black-button" disabled={loading}>{loading ? 'Wait ...' : 'Submit'}</button>
        </form>
        <p className={styles['signup']}>New user? <span onClick={() => props.changeFunction(true)}>Sign up</span> now</p>
        <div className={styles['or']}>
            <p className={styles['or-line']}></p>
            <p className={styles['or-text']}><span>OR</span></p>
        </div>
        <div className={styles['google-auth']} onClick={() => googleLogin()}>
            <img src="./assets/images/google-icon.svg" alt="google-icon" />
            <span>Sign in with Google</span>
        </div>
    </>)
}
