import React from 'react';
import { getCategory } from '../../../Utils/Common';
import Website from './QRCodeTypes/Website';
import VCard from './QRCodeTypes/VCard';
import URL from './QRCodeTypes/URL';
import styles from '../create-qr-code.module.css';

export default function Content(props){
    const details = getCategory();
    const category = JSON.parse(details);

    return (<>
        <div>
            <div className={styles['required-note']}><span>Note:</span> An asterisk (*) indicates a required field</div>
            {category.categoryType === 'website' && <Website stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
            {category.categoryType === 'card' && <VCard stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
            {category.categoryType === 'URL' && <URL stepChangeFunction={props.stepChangeFunction} categoryId={category.categoryId} />}
        </div>
    </>)
}
