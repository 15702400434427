import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import tokenapi, { generateImage, createUrlQr } from '../../../Base/TokenConfig';
import eyeRadius from './eyeRadius.json';
import { getCategory } from '../../../Utils/Common';
import { uploadImage } from '../../../Utils/uploadImage';
import Message from '../../../Layouts/Message/Message';
import Modal from '../../../Layouts/Modal/Modal';
import styles from '../create-qr-code.module.css';

export default function QRDesign(props){
    const navigate = useNavigate();
    const details = getCategory();
    const category = JSON.parse(details);
    const qrContent = JSON.parse(sessionStorage.getItem('qr-content'));
    const qrRef = useRef();
    const qrValue = qrContent.url;
    const [requestId, setRequestId] = useState('');
    const [ecLevel, setEcLevel] = useState('L');
    const [fgColor, setFgColor] = useState('#000000');
    const [logoImage, setLogoImage] = useState('');
    const [logoWidth, setLogoWidth] = useState('40');
    const [logoHeight, setLogoHeight] = useState('40');
    const [logoOpacity, setLogoOpacity] = useState('1');
    const [removeQrCodeBehindLogo, setRemoveQrCodeBehindLogo] = useState('false');
    const [logoPadding, setLogoPadding] = useState('0');
    const [logoPaddingStyle, setLogoPaddingStyle] = useState('square');
    const [qrStyle, setQrStyle] = useState('squares');
    const [eyeColor, setEyeColor] = useState('#000000');
    const [selectedEye, setSelectedEye] = useState(eyeRadius[0]);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');
    const [qrCreated, setQrCreated] = useState(false);
    const [buttonStatus, setButtonStatus] = useState('');
    const [timer, setTimer] = useState(0);

    const selectQrEye = (item) => {
        setSelectedEye(item);
    }

    const createRequestId = (length) => {
        let result = '';
        let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const uploadFunction = async (file) => {
        let image = await uploadImage(file);
        if(image.status === 'success') {
            setMessageType('success');
            setMessage(image.message);
            setLogoImage(image.result);
        } else {
            setMessageType('error');
            setMessage(image.message);
        }
    }

    const downloadQRCode = async () => {
        setButtonStatus('Creating QR');
        const requestId = await createRequestId(15);
        if(requestId) {
            setRequestId(requestId);
            setTimeout(() => {
                const canvas = qrRef.current.querySelector('canvas');
                const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                uploadQrFunction(requestId, image);
            }, 1000);
        }
    };

    const uploadQrFunction = (requestId, image) => {
        setButtonStatus('Uploading QR');
        tokenapi.post(generateImage, {
            fileName: requestId,
            encodeBase64Data: image,
            imageType: "jpeg"
        })
        .then(res => {
            if(res.data.code === 200) {
                saveQrFunction(res.data.result, requestId);
            } else {
                setButtonStatus('');
            }
        })
        .catch(err => {
            console.error(err);
            setButtonStatus('');
        })
    }

    const saveQrFunction = (qrImage, requestId) => {
        setButtonStatus('Saving QR');
        tokenapi.post(createUrlQr, {
            categoryId: category.categoryId,
            requestId: requestId,
            title: qrContent.title,
            url: qrContent.url,
            s3Url: qrImage,
            encWebUrl: `https://stage.scanonn.com/redirect/${requestId}`
        })
        .then(res => {
            if(res.data.code === 200) {
                setButtonStatus('');
                setQrCreated(true);
                startTimer(5);
            } else {
                setButtonStatus('');
            }
        })
        .catch(err => {
            console.error(err);
            setButtonStatus('');
        })
    }

    const redirectFunction = () => {
        navigate('/my-qr-codes');
    }

    const startTimer = (x) => {
        if(x > 0){
            setTimer(x);
            setTimeout(() => {
                startTimer(x - 1);
            }, 1000);
        } else {
            redirectFunction();
        }
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {qrCreated && <Modal closeModal={() => redirectFunction()}>
            <div className={styles['qr-created']}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none">
                    <mask id="mask0_45_39" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_45_39)">
                        <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill="#004367"/>
                        <path d="M2 7V2H7V4H4V7H2ZM2 22V17H4V20H7V22H2ZM17 22V20H20V17H22V22H17ZM20 7V4H17V2H22V7H20ZM17.5 17.5H19V19H17.5V17.5ZM17.5 14.5H19V16H17.5V14.5ZM16 16H17.5V17.5H16V16ZM14.5 17.5H16V19H14.5V17.5ZM13 16H14.5V17.5H13V16ZM16 13H17.5V14.5H16V13ZM14.5 14.5H16V16H14.5V14.5ZM13 13H14.5V14.5H13V13ZM19 5V11H13V5H19ZM11 13V19H5V13H11ZM11 5V11H5V5H11ZM9.5 17.5V14.5H6.5V17.5H9.5ZM9.5 9.5V6.5H6.5V9.5H9.5ZM17.5 9.5V6.5H14.5V9.5H17.5Z" fill="#004367"/>
                    </g>
                </svg>
                <h5>QR created</h5>
                <h6>You will be redirected to list page in {timer} seconds</h6>
            </div>
        </Modal>}
        <div className={styles['qr-design-box']}>
            <div className={styles['qr-design']}>
                <div className={styles['section-heading']} style={{marginTop:'0'}}>
                    <hr/>
                    <h3>Type</h3>
                </div>
                <div className={styles['create-qr-fields']}>
                    <div>
                        <label>Correction level</label>
                        <select value={ecLevel} onChange={e => setEcLevel(e.target.value)}>
                            <option value="L">Low</option>
                            <option value="M">Medium</option>
                            <option value="Q">Quartile</option>
                            <option value="H">High</option>
                        </select>
                    </div>
                    <div>
                        <label>QR style</label>
                        <select value={qrStyle} onChange={e => setQrStyle(e.target.value)}>
                            <option value="squares">squares</option>
                            <option value="dots">dots</option>
                            <option value="fluid">fluid</option>
                        </select>
                    </div>
                </div>
                <div className={styles['section-heading']}>
                    <hr/>
                    <h3>Colors</h3>
                </div>
                <div className={styles['create-qr-fields']}>
                    <div>
                        <label>QR</label>
                        <div className={styles["color-input"]}>
                            <input type="color" value={fgColor} onChange={e => setFgColor(e.target.value)} />
                            <input type="text" value={fgColor} onChange={e => setFgColor(e.target.value)} />
                        </div>
                    </div>
                    <div>
                        <label>Corner</label>
                        <div className={styles["color-input"]}>
                            <input type="color" value={eyeColor} onChange={e => setEyeColor(e.target.value)} />
                            <input type="text" value={eyeColor} onChange={e => setEyeColor(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className={styles['section-heading']}>
                    <hr/>
                    <h3>QR eyes</h3>
                </div>
                <div className={styles["qr-eyes"]}>
                    {eyeRadius.map((item, i) => (
                        <div className={styles[selectedEye.id === item.id && 'selected']} onClick={() => selectQrEye(item)} key={i}><img src={`./assets/images/qr-eyes/${item.imageName}`} alt="qr-eye" /></div>
                    ))}
                </div>
                <div className={styles['section-heading']}>
                    <hr/>
                    <h3>Logo</h3>
                </div>
                <div className={styles['create-qr-fields']}><div>
                    <label>Logo image</label>
                        {
                            logoImage
                            ?<input type="text" value="Image uploaded" disabled/>
                            :<input type="file" onChange={e => uploadFunction(e.target.files[0])} />
                        }
                    </div>
                    <div>
                        <label>Logo width</label>
                        <input value={logoWidth} onChange={e => setLogoWidth(e.target.value)} />
                    </div>
                    <div>
                        <label>Logo height</label>
                        <input value={logoHeight} onChange={e => setLogoHeight(e.target.value)} />
                    </div>
                    <div>
                        <label>Logo opacity</label>
                        <input value={logoOpacity} onChange={e => setLogoOpacity(e.target.value)} />
                    </div>
                    <div>
                        <label>Remove QR code behind logo</label>
                        <select value={removeQrCodeBehindLogo} onChange={e => setRemoveQrCodeBehindLogo(e.target.value)}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label>Logo padding</label>
                        <input value={logoPadding} onChange={e => setLogoPadding(e.target.value)} />
                    </div>
                    <div>
                        <label>Logo padding style</label>
                        <select value={logoPaddingStyle} onChange={e => setLogoPaddingStyle(e.target.value)}>
                            <option value="square">square</option>
                            <option value="circle">circle</option>
                        </select>
                    </div>
                </div>
                {
                    buttonStatus
                    ?<button className="btn btn-primary" disabled>{buttonStatus}</button>
                    :<button className="btn btn-primary" onClick={downloadQRCode}>Complete</button>
                }
            </div>
            <div className={styles['final-qr-design']}>
                <div className={styles['design-qr-heading']}>Your QR design</div>
                {
                    requestId
                    ?<div ref={qrRef}>
                        <QRCode
                            value={`https://stage.scanonn.com/redirect/${requestId}`} // string
                            ecLevel={ecLevel} // L | M | Q | H
                            enableCORS="true" // boolean
                            size="250" // number (in pixels)
                            quietZone="5" // number (in pixels)
                            bgColor="#FFFFFF" // string (css color)
                            fgColor={fgColor} // string (css color)
                            logoImage={logoImage} // string (src attribute)
                            logoWidth={logoWidth} // number (in pixels)
                            logoHeight={logoHeight} // number (in pixels)
                            logoOpacity={logoOpacity} // number (css opacity 0 <= x <= 1)
                            removeQrCodeBehindLogo={removeQrCodeBehindLogo === 'false' ? false : true} // boolean
                            logoPadding={logoPadding} // number
                            logoPaddingStyle={logoPaddingStyle} // square | circle
                            qrStyle={qrStyle} // squares | dots | fluid
                            eyeRadius={selectedEye.eyeRadius}
                            eyeColor={eyeColor}
                        />
                    </div>
                    :<QRCode
                        value={qrValue} // string
                        ecLevel={ecLevel} // L | M | Q | H
                        enableCORS="true" // boolean
                        size="250" // number (in pixels)
                        quietZone="5" // number (in pixels)
                        bgColor="#FFFFFF" // string (css color)
                        fgColor={fgColor} // string (css color)
                        logoImage={logoImage} // string (src attribute)
                        logoWidth={logoWidth} // number (in pixels)
                        logoHeight={logoHeight} // number (in pixels)
                        logoOpacity={logoOpacity} // number (css opacity 0 <= x <= 1)
                        removeQrCodeBehindLogo={removeQrCodeBehindLogo === 'false' ? false : true} // boolean
                        logoPadding={logoPadding} // number
                        logoPaddingStyle={logoPaddingStyle} // square | circle
                        qrStyle={qrStyle} // squares | dots | fluid
                        eyeRadius={selectedEye.eyeRadius}
                        eyeColor={eyeColor}
                    />
                }
                <p>
                    <span>Scan to see your QR code live</span>
                    You will be able to modify and customize the design of the code in the final step of this code creation process
                </p>
            </div>
        </div>
    </>)
}
