export const faqs = [
    {
        id: 1,
        question: 'What is a QR code?',
        answer: 'A QR code is a two-dimensional barcode that can store a lot of information in a small space. It can be scanned using a smartphone or QR code reader, and can direct the user to a website, provide contact information, or display a message.'
    },
    {
        id: 2,
        question: 'How does your QR code generator work?',
        answer: 'Our QR code generator is easy to use and requires no technical expertise. Simply enter your desired information, customize your QR code, and download it in seconds.'
    },
    {
        id: 3,
        question: 'Can I track data on my QR code scans?',
        answer: 'Yes! Our platform provides real-time analytics on your QR code scans, so you can track engagement, monitor performance, and make data-driven decisions.'
    },
    {
        id: 4,
        question: 'What customization options do you offer?',
        answer: 'We offer a range of customization options to help you create QR codes that reflect your brand and meet your specific needs. This includes custom branding, color schemes, and design elements.'
    },
    {
        id: 5,
        question: 'Can your platform integrate with other software platforms?',
        answer: 'Yes! Our platform is designed to integrate seamlessly with other software platforms, including marketing automation software, CRM systems, and more.'
    }
]