import tokenapi, { uploadImg } from "../Base/TokenConfig";

export const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            tokenapi.post(uploadImg, formData)
            .then(res => {
                if(res.data.code === 200) {
                    return resolve({
                        status: 'success',
                        message: 'Image uploaded successfully.',
                        result: res.data.result
                    });
                } else {
                    return resolve({
                        status: 'error',
                        message: res.data.message
                    });
                }
            })
            .catch(err => {
                console.error(err);
                return resolve({
                    status: 'error',
                    message: 'Something went wrong in uploading image.'
                });
            })
        } catch (err) {
            console.error(err)
            return reject(err)
        }
    })
}