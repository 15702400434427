import React from 'react';
import styles from "./structure.module.css";

export default function Structure(){
    return (<>
        <div className={styles['structure']}>
            <table className="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Class</th>
                        <th>Element</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>label</td>
                        <td>-</td>
                        <td><label>Label</label></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>input</td>
                        <td>-</td>
                        <td><input /></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>select</td>
                        <td>-</td>
                        <td>
                            <select>
                                <option>-- Select --</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>button</td>
                        <td>btn</td>
                        <td><button className="btn">Button</button></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>light button</td>
                        <td>btn btn-light</td>
                        <td><button className="btn btn-light">Button</button></td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>default button</td>
                        <td>btn btn-default</td>
                        <td><button className="btn btn-default">Button</button></td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>link button</td>
                        <td>btn btn-link</td>
                        <td><button className="btn btn-link">Button</button></td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>primary button</td>
                        <td>btn btn-primary</td>
                        <td><button className="btn btn-primary">Button</button></td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>red button</td>
                        <td>btn btn-danger</td>
                        <td><button className="btn btn-danger">Button</button></td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>success button</td>
                        <td>btn btn-success</td>
                        <td><button className="btn btn-success">Button</button></td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>info button</td>
                        <td>btn btn-info</td>
                        <td><button className="btn btn-info">Button</button></td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>warning button</td>
                        <td>btn btn-warning</td>
                        <td><button className="btn btn-warning">Button</button></td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>button</td>
                        <td>button</td>
                        <td><button className="button">Button</button></td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>black button</td>
                        <td>button black-button</td>
                        <td><button className="button black-button">Button</button></td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>secondary button</td>
                        <td>btn btn-secondary</td>
                        <td><button className="btn btn-secondary">Button</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>)
}
