import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { getToken } from './Utils/Common';
import PageLayout from './Layouts/PageLayout/PageLayout';
import UserLayout from './Layouts/UserLayout/UserLayout';
import AdminLayout from './Layouts/AdminLayout/AdminLayout';
import Structure from './Layouts/Structure/Structure';
import Home from './Pages/Home/Home';
import Product from './Pages/Product/Product';
import About from './Pages/About/About';
import ContactUs from './Pages/ContactUs/ContactUs';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import FAQs from './Pages/FAQs/FAQs';
import Pricing from './Pages/Pricing/Pricing';
import Login from './User/Login/Login';
import MyQRCodes from './User/MyQRCodes/MyQRCodes';
import MyQRCodeDetail from './User/MyQRCodeDetail/MyQRCodeDetail';
import CreateQRCode from './User/CreateQRCode/CreateQRCode';
import Profile from './User/Profile/Profile';
import Logout from './User/Logout/Logout';
import AdminLogin from './Admin/Login/Login';
import AdminUsers from './Admin/Users/Users';
import AdminUserDetail from './Admin/Users/UserDetail';
import AdminQRCategory from './Admin/QRCategory/QRCategory';
import AdminLogout from './Admin/Logout/Logout';
import VerifyEmail from './User/ActionPages/VerifyEmail';
import ChangePassword from './User/ActionPages/ChangePassword';
import VcardDetails from './User/QRDetails/VcardDetails';
import Redirect from './User/QRDetails/Redirect';

function App() {
    // Function to check if the user is authenticated
    const isUserAuthenticated = () => {
        if(getToken()){
            return true;
        } else {
            return false;
        }
    };
    
    const PrivateRouteWrapper = ({ element }) => {
        // If the user is not authenticated, redirect to the login page
        if (!isUserAuthenticated()) {
            return <Navigate to="/login" />;
        }
      
        // Render the private route if the user is authenticated
        return element;
    };

    const PublicRouteWrapper = ({ element }) => {
        // If the user is authenticated, redirect to the dashboard page
        if (isUserAuthenticated()) {
            return <Navigate to="/my-qr-codes" />;
        }
      
        // Render the public route if the user is not authenticated
        return element;
    };

    return (<>
        <BrowserRouter>
            <Routes>
                <Route path='/structure' element={<Structure />} />
                {/* ========== Static pages ========== */}
                <Route exact path='/' element={<PageLayout><Home /></PageLayout>} />
                <Route path='/product' element={<PageLayout><Product /></PageLayout>} />
                <Route path='/about' element={<PageLayout><About /></PageLayout>} />
                <Route path='/contact-us' element={<PageLayout><ContactUs /></PageLayout>} />
                <Route path='/privacy-policy' element={<PageLayout><PrivacyPolicy /></PageLayout>} />
                <Route path='/faqs' element={<PageLayout><FAQs /></PageLayout>} />
                <Route path='/pricing' element={<PageLayout><Pricing /></PageLayout>} />
                
                {/* ========== Dashboard pages ========== */}
                <Route path="/login" element={<PublicRouteWrapper element={<Login />} />} />
                <Route path='/my-qr-codes' element={<UserLayout><PrivateRouteWrapper element={<MyQRCodes />} /></UserLayout>} />
                <Route path='/qr-code-detail' element={<UserLayout><PrivateRouteWrapper element={<MyQRCodeDetail />} /></UserLayout>} />
                <Route path='/create-qr-code' element={<UserLayout><PrivateRouteWrapper element={<CreateQRCode />} /></UserLayout>} />
                <Route path='/profile' element={<UserLayout><PrivateRouteWrapper element={<Profile />} /></UserLayout>} />
                <Route path='/logout' element={<Logout />} />

                {/* ========== Action pages ========== */}
                <Route path='/verify-email/:string' element={<VerifyEmail />} />
                <Route path='/change-password/:string' element={<ChangePassword />} />
                <Route path='/vcard-details/:string' element={<VcardDetails />} />
                <Route path='/redirect/:string' element={<Redirect />} />
                
                {/* ========== Admin pages ========== */}
                <Route path='/admin/login' element={<AdminLogin />} />
                <Route path='/admin/users' element={<AdminLayout><AdminUsers /></AdminLayout>} />
                <Route path='/admin/user-detail' element={<AdminLayout><AdminUserDetail /></AdminLayout>} />
                <Route path='/admin/qr-category' element={<AdminLayout><AdminQRCategory /></AdminLayout>} />
                <Route path='/admin/logout' element={<AdminLayout><AdminLogout /></AdminLayout>} />
            </Routes>
        </BrowserRouter>
    </>);
}

export default App;