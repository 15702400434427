import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import adminapi, { getQrList, deleteUserFromAdmin, resendVerification } from '../../Base/AdminConfig';
import { getQrDetail } from '../../Utils/Common';
import Message from '../../Layouts/Message/Message';
import styles from './users.module.css';

export default function UserDetail(){
    const navigate = useNavigate();
    const userDetail = getQrDetail();
    const [ qrDetail, setQrDetail ] = useState([]);
    const [ resendLoading, setResendLoading ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ messageType, setMessageType ] = useState('');
    const [ message, setMessage ] = useState('');

    useEffect(() => {
        qrCreated(userDetail.userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const qrCreated = (userId) => {
        // setQrLoading(true);
        adminapi.get(getQrList + userId)
        .then(res => {
            // setQrLoading(false);
            if(res.data.code === 200){
                setQrDetail(res.data.records);
                // setMessageType('success');
            } else {
                // setMessageType('error');
            }
            // setMessage(res.data.message);
        })
        .catch(err => {
            // setQrLoading(false);
            console.error(err);
        })
    }

    const deleteUser = () => {
        setLoading(true);
        adminapi.delete(deleteUserFromAdmin + userDetail.userId)
        .then(res => {
            setLoading(false);
            if(res.data.code === 200){
                setMessageType('success');
                navigate('/admin/users');
            } else {
                setMessageType('error');
                setTimeout(() => {
                }, 1000)
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setLoading(false);
            console.error(err);
        })
    }

    const resendVerificationFunction = () => {
        setResendLoading(true);
        adminapi.post(resendVerification, {
            email: userDetail.email
        })
        .then(res => {
            setResendLoading(false);
            if(res.data.code === 200){
                setMessageType('success');
                navigate('/admin/users');
            } else {
                setMessageType('error');
                setTimeout(() => {
                }, 1000)
            }
            setMessage(res.data.message);
        })
        .catch(err => {
            setResendLoading(false);
            console.error(err);
        })
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        <h1>Users <i className="fa fa-angle-right"></i> detail</h1>
        <h6 className={styles['card-heading']}>User Details</h6>
        <div className={styles['user-detail']}>
            <p><span>User id</span> {userDetail.userId}</p>
            <p><span>Customer id</span> {userDetail.customerId}</p>
            <p><span>First name</span> {userDetail.firstName}</p>
            <p><span>Last name</span> {userDetail.lastName}</p>
            <p><span>Email</span> {userDetail.email}</p>
            <p><span>Phone</span> {userDetail.phone ? userDetail.phone : 'Not added'}</p>
            <p><span>Created at</span> {`${new Date(userDetail.createdAt)}`.substr(0, 24)}</p>
            <p><span>Status</span> {userDetail.isActive ? 'Active' : 'Inactive' }</p>
            <p><span>Password</span> {userDetail.password ? 'True' : 'False'}</p>
            <p><span>Role</span> {userDetail.role}</p>
            <div>
                {
                    loading
                    ?<button className="btn btn-danger mt-3 mb-2 mr-3" disabled>Deleting</button>
                    :<button className="btn btn-danger mt-3 mb-2 mr-3" onClick={() => deleteUser()}>Delete</button>
                }
                {!userDetail.isActive && <>
                    {
                        resendLoading
                        ?<button className="btn btn-primary mt-3 mb-2 mr-3" disabled>Resend verification mail</button>
                        :<button className="btn btn-primary mt-3 mb-2" onClick={() => resendVerificationFunction()}>Resend verification mail</button>
                    }
                </>}
            </div>
        </div>
        <h6 className={styles['card-heading']}>QR created {qrDetail.length > 0 ? `(${qrDetail.length})` : ''}</h6>
        {
            qrDetail.length > 0
            ?<div className={styles['qr-detail-cards']}>
                {qrDetail.map((item, i) => (
                    <div className={styles['qr-detail']} key={i}>
                        <p><span>Title:</span> {item.title}</p>
                        <p><span>Category name:</span> {item.categoryName}</p>
                        <p><span>Quality:</span> {item.quality}</p>
                        <p><span>Dark color:</span> {item.color.dark}</p>
                        <p><span>Light color:</span> {item.color.light}</p>
                        <p><span>Total scans:</span> {item.totalScans}</p>
                        <p><span>Created at:</span> {`${new Date(item.createdAt)}`.substr(0, 24)}</p>
                        <span>Details</span>
                    </div>
                ))}
            </div>
            :<div className={styles['no-records']}>
                <span>no QR created</span>
            </div>
        }
    </>)
}