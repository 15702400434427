import React, { useState, useEffect } from 'react';
import tokenapi, { getVisitors } from '../../../Base/TokenConfig';
import Message from '../../../Layouts/Message/Message';
import Modal from '../../../Layouts/Modal/Modal';
import styles from '../my-qr-code-detail.module.css';

export default function VisitorDetails(props){
    const [visitorsDetails, setVisitorsDetails] = useState([]);
    const [detailModal, setDetailModal] = useState(false);
    const [visitorDetail, setVisitorDetail] = useState('');
    const [loader, setLoader] = useState(true);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchFunction(props.data.requestId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchFunction = (id) => {
        setLoader(true);
        tokenapi.get(getVisitors + id)
        .then(res => {
            setLoader(false);
            if(res.data.code === 200) {
                setVisitorsDetails(res.data.result);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            setLoader(false);
            console.error(err);
        })
    }

    const openVisitorDetail = (detail) => {
        setDetailModal(true);
        setVisitorDetail(detail);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {detailModal && <Modal closeModal={() => setDetailModal(false)}>
            <div className={styles['qr-visitor-modal']}>
                <div className={styles['modal-heading']}>Visitor details</div>
                <div className={styles['modal-content']}>
                    <p><span>IP:</span> {visitorDetail.location?.ip}</p>
                    <p><span>Device:</span> {visitorDetail.deviceInfo}</p>
                    <p><span>Created at:</span> {`${new Date(visitorDetail.createdAt)}`.substr(0,24)}</p>
                    <p><span>Updated at:</span> {`${new Date(visitorDetail.updatedAt)}`.substr(0,24)}</p>
                    <p><span>Device details:</span> {visitorDetail.deviceDetails}</p>
                    <p><span>Languages:</span> {visitorDetail.location?.languages}</p>
                    <p><span>Continent code:</span> {visitorDetail.location?.continent_code}</p>
                    <p><span>Continent name:</span> {visitorDetail.location?.continent_name}</p>
                    <p><span>Country code2:</span> {visitorDetail.location?.country_code2}</p>
                    <p><span>Country code3:</span> {visitorDetail.location?.country_code3}</p>
                    <p><span>Country name:</span> {visitorDetail.location?.country_name}</p>
                    <p><span>Country name official:</span> {visitorDetail.location?.country_name_official}</p>
                    <p><span>Country capital:</span> {visitorDetail.location?.country_capital}</p>
                    <p><span>State prov:</span> {visitorDetail.location?.state_prov}</p>
                    <p><span>State code:</span> {visitorDetail.location?.state_code}</p>
                    <p><span>District:</span> {visitorDetail.location?.district}</p>
                    <p><span>City:</span> {visitorDetail.location?.city}</p>
                    <p><span>Zipcode:</span> {visitorDetail.location?.zipcode}</p>
                    <p><span>Latitude:</span> {visitorDetail.location?.latitude}</p>
                    <p><span>Longitude:</span> {visitorDetail.location?.longitude}</p>
                    <p><span>Is eu:</span> {visitorDetail.location?.is_eu}</p>
                    <p><span>Calling code:</span> {visitorDetail.location?.calling_code}</p>
                    <p><span>Country tld:</span> {visitorDetail.location?.country_tld}</p>
                    <p><span>Country flag:</span> {visitorDetail.location?.country_flag}</p>
                    <p><span>Geoname id:</span> {visitorDetail.location?.geoname_id}</p>
                    <p><span>ISP:</span> {visitorDetail.location?.isp}</p>
                    <p><span>Connection type:</span> {visitorDetail.location?.connection_type}</p>
                    <p><span>Organization:</span> {visitorDetail.location?.organization}</p>
                    <p><span>Country emoji:</span> {visitorDetail.location?.country_emoji}</p>
                    <p><span>Code:</span> {visitorDetail.location?.currency?.code}</p>
                    <p><span>Name:</span> {visitorDetail.location?.currency?.name}</p>
                    <p><span>Symbol:</span> {visitorDetail.location?.currency?.symbol}</p>
                    <p><span>Name:</span> {visitorDetail.location?.time_zone?.name}</p>
                    <p><span>Offset:</span> {visitorDetail.location?.time_zone?.offset}</p>
                    <p><span>Offset with dst:</span> {visitorDetail.location?.time_zone?.offset_with_dst}</p>
                    <p><span>Current time:</span> {visitorDetail.location?.time_zone?.current_time}</p>
                    <p><span>Current time unix:</span> {visitorDetail.location?.time_zone?.current_time_unix}</p>
                    <p><span>Is DST:</span> {visitorDetail.location?.time_zone?.is_dst ? 'True' : 'False'}</p>
                    <p><span>DST savings:</span> {visitorDetail.location?.time_zone?.dst_savings}</p>
                    <p><span>DST exists:</span> {visitorDetail.location?.time_zone?.dst_exists ? 'True' : 'False'}</p>
                    <p><span>DST start:</span> {visitorDetail.location?.time_zone?.dst_start ? visitorDetail.location.time_zone.dst_start : 'False'}</p>
                    <p><span>DST end:</span> {visitorDetail.location?.time_zone?.dst_end ? visitorDetail.location.time_zone.dst_end : 'False'}</p>
                </div>
            </div>
        </Modal>}
        {
            loader
            ?<>
                <div className={styles['loader-box']} style={{height:'80px',marginBottom:'2px'}}></div>
                <div className={styles['loader-box']} style={{height:'80px',marginBottom:'2px'}}></div>
                <div className={styles['loader-box']} style={{height:'80px',marginBottom:'2px'}}></div>
                <div className={styles['loader-box']} style={{height:'80px',marginBottom:'2px'}}></div>
            </>
            :<>
                {
                    visitorsDetails.length > 0
                    ?<div className={styles['visitor-cards']}>
                        {visitorsDetails.map((item, i) => (
                            <div className={styles['visitor-card']} key={i}>
                                <p><span>IP:</span> {item.location.ip}</p>
                                <p><span>First scan:</span> {`${new Date(item.createdAt)}`.substr(4,20)}</p>
                                <p><span>Device details:</span> {item.deviceInfo}</p>
                                <p><span>Country:</span> {item.location.country_emoji} {item.location.country_name}</p>
                                <p className={styles['visitor-detail']} onClick={() => openVisitorDetail(item)}>All details</p>
                            </div>
                        ))}
                    </div>
                    :<div className={styles['no-visitor']}> No visitors found </div>
                }
            </>
        }
    </>)
}