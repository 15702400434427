import React, { useState } from 'react';
import tokenapi, { createWebsiteQR } from '../../../../Base/TokenConfig';
import { addHttps } from '../../../../Utils/addHttps';
import { qualityOptions } from '../Common';
import Message from '../../../../Layouts/Message/Message';
import Modal from '../../../../Layouts/Modal/Modal';
import styles from '../../create-qr-code.module.css';

export default function Website(props) {
    const [title, setTitle] = useState('');
    const [website, setWebsite] = useState('');
    const [darkColor, setDarkColor] = useState('#000000');
    const [lightColor, setLightColor] = useState('#FFFFFF');
    const [quality, setQuality] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [detailModal, setDetailModal] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [message, setMessage] = useState('');

    const createFunction = (e) => {
        e.preventDefault();
        tokenapi.post(createWebsiteQR, {
            title: title,
            url: addHttps(website),
            color: {
                dark: darkColor,
                light: lightColor
            },
            quality: quality,
            categoryId: props.categoryId
        })
        .then(res => {
            if(res.data.code === 200){
                setMessageType('success');
                setMessage(res.data.message);
                setQrCode(res.data.result.S3Url);
                setDetailModal(true);
                // sessionStorage.setItem('S3Url', res.data.result.S3Url);
                // props.stepChangeFunction(3);
            } else {
                setMessageType('error');
                setMessage(res.data.message);
            }
        })
        .catch(err => {
            console.error(err);
        })
    }

    const closeModal = () => {
        props.stepChangeFunction(1);
    }

    return (<>
        {message && <Message type={messageType} message={message} closeMessage={() => setMessage('')} />}
        {detailModal && <Modal closeModal={() => closeModal()}>
            <div className={styles["modal-box"]}>
                <div className={styles['modal-heading']}>Details</div>
                <div className={styles["modal-content"]}>
                    <div>
                        <img src={qrCode} style={{borderColor:`${darkColor}`}} alt="qr" />
                    </div>
                    <div>
                        {/* <p><span>Created on</span> {`${new Date(singleDetail.createdAt)}`.substr(0, 24)}</p> */}
                        <p><span>Title</span> {title}</p>
                        <p><span>URL</span> {addHttps(website)}</p>
                        <p><span>Quality</span> {quality}</p>
                        <p><span>Dark color</span> <span className={styles['color-box']}><input type="color" defaultValue={darkColor} disabled/>{darkColor}</span></p>
                        <p><span>Light color</span> <span className={styles['color-box']}><input type="color" defaultValue={lightColor} disabled/>{lightColor}</span></p>
                    </div>
                </div>
                <button className="btn btn-secondary mt-3" onClick={() => closeModal()}>Close</button>
            </div>
        </Modal>}
        <form onSubmit={createFunction}>
            <div className={styles['qr-title']}>
                <label>Title*</label>
                <input type="text" placeholder="Enter QR title here" value={title} onChange={e => setTitle(e.target.value)} required/>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>Website details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>URL*</label>
                    <input type="text" placeholder="Enter website url" value={website} onChange={e => setWebsite(e.target.value)} required/>
                </div>
            </div>
            <div className={styles['section-heading']}>
                <hr/>
                <h3>QR code details</h3>
            </div>
            <div className={styles['create-qr-fields']}>
                <div>
                    <label>Quality*</label>
                    <select value={quality} onChange={e => setQuality(e.target.value)} required>
                        <option value="">-- Select any one --</option>
                        {qualityOptions.map((item, i) => (
                            <option value={item} key={i}>{item}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Dark color*</label>
                    <div className={styles['color-inputs']}>
                        <input type="color" value={darkColor} onChange={e => setDarkColor(e.target.value)} required/>
                        <span></span>
                        <input type="text" placeholder="Enter website url" value={darkColor} onChange={e => setDarkColor(e.target.value)} required/>
                    </div>
                </div>
                <div>
                    <label>Light color*</label>
                    <div className={styles['color-inputs']}>
                        <input type="color" value={lightColor} onChange={e => setLightColor(e.target.value)} required/>
                        <span></span>
                        <input type="text" placeholder="Enter website url" value={lightColor} onChange={e => setLightColor(e.target.value)} required/>
                    </div>
                </div>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
    </>)
}